import React, { useState } from "react";
import { Navbar, Nav, Button } from "react-bootstrap";
import "./Navbar.css"; 

const HospitalNavbar = () => {
  const [expanded, setExpanded] = useState(false);

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  const handleClose = () => {
    setExpanded(false);
  };

  return (
    <Navbar
      bg="dark"
      variant="dark"
      expand="lg"
      className="hospital-navbar"
      expanded={expanded}
    >
      <Navbar.Brand href="#">
        <img
          src="https://via.placeholder.com/50"
          width="50"
          height="50"
          className="d-inline-block align-top"
          alt="Hospital Listings"
        />
        Hospital Listings
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={handleToggle} />
      <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
        <Button className="close-btn d-lg-none" onClick={handleClose}>
          &times;
        </Button>
        <Nav className="ml-auto">
          <Nav.Link href="#" onClick={handleClose}>Home</Nav.Link>
          <Nav.Link href="#" onClick={handleClose}>About</Nav.Link>
          <Nav.Link href="#" onClick={handleClose}>Contact</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default HospitalNavbar;
