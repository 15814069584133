import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import HeroImage from "../assets/images/hero.jpg";

const Hero = () => {
  return (
    <div id="hero-image" className="hero">
      <Container>
        <Row className="align-items-center">
          <Col md={6}>
            <h1 className="hero-title">Welcome to Hospital Listings</h1>
            <p className="hero-description">
              We are committed to providing the best healthcare services to our
              community. Our team of experienced specialists is here to ensure
              you receive the highest quality of care.
            </p>
          </Col>
          <Col md={6}>
            <img src={HeroImage} alt="Hospital Image" className="img-fluid" />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Hero;
