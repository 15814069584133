import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { Row, Col, Card, ListGroup, Container } from "react-bootstrap";
import "./HospitalDetail.css";
import DefaultLogo from "../assets/images/logod.jpg"; // Default logo image
import DefaultHospitalImage from "../assets/images/defaulth.jpg"; // Default hospital image

const HospitalDetail = () => {
  const { id } = useParams(); // Hospital ID from URL
  const [hospital, setHospital] = useState(null); // State to hold hospital details
  const [loading, setLoading] = useState(true); // Loading state

  useEffect(() => {
    const fetchHospitalDetail = async () => {
      try {
        const response = await fetch(
          `https://healthadmin.andcircles.com/api/hospital/${id}`
        );
        const result = await response.json();

        if (result.code === 200) {
          setHospital(result.data);
        } else {
          console.error("Failed to fetch hospital details", result.message);
        }
      } catch (error) {
        console.error("Error fetching hospital details", error);
      } finally {
        setLoading(false);
      }
    };

    fetchHospitalDetail();
  }, [id]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!hospital) {
    return <div>Hospital not found</div>;
  }

  // Function to get image URL with fallback to appropriate default image
  const getImageUrl = (imagePath, type) => {
    if (imagePath) {
      return `https://healthadmin.andcircles.com/${imagePath}`;
    }
    return type === "logo" ? DefaultLogo : DefaultHospitalImage;
  };

  return (
    <>
      <Row className="inner-hero full-width-section">
        <Col className="text-center">
          <h1 className="inner-hero-title">{hospital.name}</h1>
          <p className="inner-hero-description">
            We are committed to providing the best healthcare services to our
            community. Our team of experienced specialists is here to ensure you
            receive the highest quality of care.
          </p>
        </Col>
      </Row>

      <Container className="my-4">
        <Row>
          <Col md={4} className="mb-4">
            <Card className="sticky-card position-relative">
              {hospital.is_verified === 1 && (
                <div className="verification-badge badge-verified">
                  Verified
                </div>
              )}
              {hospital.is_verified === 0 && (
                <div className="verification-badge badge-not-verified">
                  Not Verified
                </div>
              )}
              <Card.Img
                variant="top"
                src={getImageUrl(hospital.logo, "logo")}
                alt={hospital.name}
                className="hospital-logo img-fluid"
              />
              <Card.Body>
                <Card.Title>{hospital.name}</Card.Title>
                <Card.Text>
                  <strong>Contact Number:</strong> {hospital.phone}
                </Card.Text>
                <Card.Text>
                  <strong>Location:</strong> {hospital.location}
                </Card.Text>
                <Card.Text>
                  <strong>Website:</strong>{" "}
                  <a href={hospital.website} target="_blank">{hospital.website}</a>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          <Col md={8} className="mb-4">
            <Card className="p-4 bg-white shadow-sm rounded">
              <Card.Img
                variant="top"
                src={getImageUrl(hospital.image, "hospital")}
                alt="Hospital"
                className="img-fluid mb-4 rounded hospital-image"
              />
              <Card.Body>
                <Card.Title>About</Card.Title>
                <Card.Text
                  dangerouslySetInnerHTML={{ __html: hospital.about }}
                ></Card.Text>
              </Card.Body>
            </Card>

            <Card className="doctors-card mt-4">
              <Card.Body>
                <Card.Title>Doctors</Card.Title>
                <ListGroup variant="flush">
                  {hospital.doctors && hospital.doctors.length > 0 ? (
                    hospital.doctors.map((doctor) => (
                      <ListGroup.Item key={doctor.id}>
                        <Link to={`/doctor/${doctor.id}`}>{doctor.name}</Link>
                      </ListGroup.Item>
                    ))
                  ) : (
                    <ListGroup.Item>No doctors available</ListGroup.Item>
                  )}
                </ListGroup>
              </Card.Body>
            </Card>

            <Card className="specialists-card mt-4">
              <Card.Body>
                <Card.Title>Specialists</Card.Title>
                <ListGroup variant="flush">
                  {hospital.specialization
                    .split(",")
                    .map((specialist, index) => (
                      <ListGroup.Item key={index}>{specialist}</ListGroup.Item>
                    ))}
                </ListGroup>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default HospitalDetail;
