import React from 'react';
import { Container, Row, Col, ListGroup, Image } from 'react-bootstrap';
// import Logo from '../assets/images/logo.png'; 

const Footer = () => {
  return (
    <footer className="bg-dark text-white py-4">
      <Container>
        <Row>
          {/* Logo and About Company */}
          <Col md={4} className="mb-4 mb-md-0">
            <Image src="https://via.placeholder.com/50" alt="Company Logo" className="mb-3" fluid />
            <h5>About Us</h5>
            <p>
              We are committed to providing the best healthcare services with a team of experienced specialists.
              Our goal is to ensure you receive top-notch care with a focus on innovation and quality.
            </p>
          </Col>

          {/* Services */}
          <Col md={4} className="mb-4 mb-md-0">
            <h5>Services</h5>
            <ListGroup variant="flush">
              <ListGroup.Item className="bg-dark text-white border-0">
                <a href="/services/consultations" className="text-white text-decoration-none">Consultations</a>
              </ListGroup.Item>
              <ListGroup.Item className="bg-dark text-white border-0">
                <a href="/services/diagnostics" className="text-white text-decoration-none">Diagnostics</a>
              </ListGroup.Item>
              <ListGroup.Item className="bg-dark text-white border-0">
                <a href="/services/surgery" className="text-white text-decoration-none">Surgery</a>
              </ListGroup.Item>
              <ListGroup.Item className="bg-dark text-white border-0">
                <a href="/services/therapy" className="text-white text-decoration-none">Therapy</a>
              </ListGroup.Item>
            </ListGroup>
          </Col>

          {/* Important Links */}
          <Col md={4}>
            <h5>Important Links</h5>
            <ListGroup variant="flush">
              <ListGroup.Item className="bg-dark text-white border-0">
                <a href="/about" className="text-white text-decoration-none">About Us</a>
              </ListGroup.Item>
              <ListGroup.Item className="bg-dark text-white border-0">
                <a href="/contact" className="text-white text-decoration-none">Contact Us</a>
              </ListGroup.Item>
              <ListGroup.Item className="bg-dark text-white border-0">
                <a href="/privacy-policy" className="text-white text-decoration-none">Privacy Policy</a>
              </ListGroup.Item>
              <ListGroup.Item className="bg-dark text-white border-0">
                <a href="/terms-of-service" className="text-white text-decoration-none">Terms of Service</a>
              </ListGroup.Item>
            </ListGroup>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col className="text-center">
            <p className="mb-0">&copy; {new Date().getFullYear()} Health &circles. All rights reserved.</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
