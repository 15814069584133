import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import HospitalNavbar from "./component/Navbar";
import Hero from "./component/Hero";
import "./App.css";
import HospitalList from "./component/HospitalList";
import HospitalDetail from "./component/HospitalDetail";
import DoctorDetail from "./component/DoctorDetail";
import Footer from "./component/Footer";

function App() {
  return (
    <Router>
      <div>
        <HospitalNavbar />
        <ConditionalHero />
        <Routes>
          <Route path="/" element={<HospitalList />} />
          <Route path="/hospital/:id" element={<HospitalDetail />} />
          <Route path="/doctor/:id" element={<DoctorDetail />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

// Component to conditionally render Hero
function ConditionalHero() {
  const location = useLocation();
  const isHomePage = location.pathname === "/";

  return isHomePage ? <Hero /> : null;
}

export default App;
