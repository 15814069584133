import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Row, Col, Card, Container } from "react-bootstrap";
import DefaultDoctorImage from "../assets/images/default-doctor.jpg"; // Default doctor image

const DoctorDetail = () => {
  const { id } = useParams();
  const [doctor, setDoctor] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchDoctorDetail = async () => {
      try {
        const response = await fetch(`https://healthadmin.andcircles.com/api/doctor/${id}`);
        const result = await response.json();

        if (result.code === 200) {
          setDoctor(result.data);
        } else {
          console.error("Failed to fetch doctor details", result.message);
          setDoctor(null);
        }
      } catch (error) {
        console.error("Error fetching doctor details", error);
        setDoctor(null);
      } finally {
        setLoading(false);
      }
    };

    fetchDoctorDetail();
  }, [id]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!doctor) {
    return <div>Doctor not found</div>;
  }

  // Function to get image URL with fallback to default doctor image
  const getImageUrl = (imagePath) => {
    if (imagePath) {
      return `https://healthadmin.andcircles.com/${imagePath}`;
    }
    return DefaultDoctorImage;
  };

  return (
    <>
      <Row className="inner-hero full-width-section">
        <Col className="text-center">
          <h1 className="inner-hero-title">{doctor.name}</h1>
          <p className="inner-hero-description">
            {doctor.specialization} with {doctor.experience} years of experience.
          </p>
        </Col>
      </Row>

      <Container className="my-4">
        <Row>
          <Col md={4} className="mb-4">
            <Card className="sticky-card position-relative">
              {doctor.is_verified === "1" && (
                <div className="verification-badge badge-verified">Verified</div>
              )}
              {doctor.is_verified === "0" && (
                <div className="verification-badge badge-not-verified">Not Verified</div>
              )}
              <Card.Img
                variant="top"
                src={getImageUrl(doctor.image)}
                alt={doctor.name}
                className="doctor-image img-fluid"
              />
              <Card.Body>
                <Card.Title>{doctor.name}</Card.Title>
                <Card.Text>
                  <strong>Specialization:</strong> {doctor.specialization}
                </Card.Text>
                <Card.Text>
                  <strong>Experience:</strong> {doctor.experience} years
                </Card.Text>
                <Card.Text>
                  <strong>Education:</strong> {doctor.education}
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          <Col md={8} className="mb-4">
            <Card className="p-4 bg-white shadow-sm rounded">
              <Card.Body>
                <Card.Title>About</Card.Title>
                <Card.Text dangerouslySetInnerHTML={{ __html: doctor.about }}></Card.Text>
              </Card.Body>
            </Card>

            <Card className="publications-card mt-4">
              <Card.Body>
                <Card.Title>Publications</Card.Title>
                <Card.Text>{doctor.publications || "No publications available"}</Card.Text>
              </Card.Body>
            </Card>

            <Card className="job-details-card mt-4">
              <Card.Body>
                <Card.Title>Job Details</Card.Title>
                <Card.Text>{doctor.job_details || "No job details available"}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default DoctorDetail;
