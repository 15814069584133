import React, { useState, useEffect, useCallback } from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import HospitalImage from "../assets/images/logod.jpg"; // Ensure the correct import path

const HospitalList = () => {
  const [hospitals, setHospitals] = useState([]);
  const [visibleHospitals, setVisibleHospitals] = useState([]);
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(8);
  const [loading, setLoading] = useState(false);
  const [allLoaded, setAllLoaded] = useState(false);
  const [query, setQuery] = useState("");

  const fetchHospitalData = useCallback(async () => {
    try {
      const response = await fetch("https://healthadmin.andcircles.com/api/hospitals-list");
      const result = await response.json();

      console.log("API Response:", result); // Debugging line

      if (result.status === "success" && Array.isArray(result.data)) {
        console.log("Hospital Data:", result.data); // Debugging line
        setHospitals(result.data);
        setStart(0);
        setEnd(8);
        setAllLoaded(false);
        setVisibleHospitals(result.data.slice(0, 9));
      } else {
        console.error("Failed to fetch hospital data or data format is incorrect", result.message);
      }
    } catch (error) {
      console.error("Error fetching hospital data", error);
    }
  }, []);

  const loadMoreHospitals = useCallback(() => {
    setLoading(true);
    setTimeout(() => {
      const filteredHospitals = hospitals.filter((hospital) =>
        hospital.name.toLowerCase().includes(query.toLowerCase())
      );

      const newStart = end + 1;
      const newEnd = Math.min(newStart + 8, filteredHospitals.length - 1);

      const newHospitals = filteredHospitals.slice(newStart, newEnd + 1);

      setVisibleHospitals((prevHospitals) => [
        ...prevHospitals,
        ...newHospitals,
      ]);

      setStart(newStart);
      setEnd(newEnd);

      if (newEnd >= filteredHospitals.length - 1) {
        setAllLoaded(true);
      }
      setLoading(false);
    }, 1000);
  }, [end, query, hospitals]);

  useEffect(() => {
    fetchHospitalData();
  }, [fetchHospitalData]);

  const handleSearch = (e) => {
    const searchQuery = e.target.value.toLowerCase();
    setQuery(searchQuery);
    setStart(0);
    setEnd(8);

    const filteredHospitals = hospitals.filter((hospital) =>
      hospital.name.toLowerCase().includes(searchQuery)
    );

    console.log("Filtered Hospitals:", filteredHospitals); // Debugging line

    setVisibleHospitals(filteredHospitals.slice(0, 9));
    setAllLoaded(filteredHospitals.length <= 9); // Adjust 'allLoaded' flag based on the number of filtered results
  };

  const handleScroll = useCallback(() => {
    if (
      window.innerHeight + window.scrollY >=
      document.body.offsetHeight - 100
    ) {
      if (!loading && !allLoaded) {
        loadMoreHospitals();
      }
    }
  }, [loading, allLoaded, loadMoreHospitals]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  const getImageUrl = (imagePath) => {
    return imagePath
      ? `https://healthadmin.andcircles.com/${imagePath}`
      : HospitalImage;
  };

  return (
    <Container className="my-4">
      <Row className="mb-4">
        <Col>
          <input
            type="text"
            className="form-control"
            id="search"
            placeholder="Search hospitals..."
            value={query}
            onChange={handleSearch}
          />
        </Col>
        <Col xs="auto" className="text-right">
          <p>Total Hospitals: {hospitals.length}</p>
        </Col>
      </Row>
      <Row id="hospital-list">
        {visibleHospitals.length > 0 ? (
          visibleHospitals.map((hospital, index) => (
            <Col md={4} className="mb-4" key={hospital.id}>
              <Card className="h-100 position-relative">
                <Card.Img
                  className="hospital-logo"
                  variant="top"
                  src={getImageUrl(hospital.image)}
                  alt={`${hospital.name} Logo`}
                />
                <Card.Body className="text-center">
                  <div className="hospital-badge">{index + 1}</div>
                  <Card.Title>{hospital.name}</Card.Title>
                  <Card.Text>{hospital.location}</Card.Text>
                  <Button
                    variant="primary"
                    as={Link}
                    to={`/hospital/${hospital.id}`}
                  >
                    View Details
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          ))
        ) : (
          <Col>
            <p>No hospitals found.</p>
          </Col>
        )}
      </Row>
      {!allLoaded && !loading && (
        <div className="text-center mt-4">
          <Button variant="primary" onClick={loadMoreHospitals}>
            Load More
          </Button>
        </div>
      )}
      {loading && (
        <div className="text-center mt-4">
          <Button variant="primary" disabled>
            Loading...
          </Button>
        </div>
      )}
    </Container>
  );
};

export default HospitalList;
